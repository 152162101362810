.App {
  width: 100dvw;
  height: 100dvh;
  position: absolute;
  margin: 0;
  padding: 0;
}

.page {
  width: 100%;
  height: 100%;
  position: absolute;
}

canvas {
  display: block;
  vertical-align: bottom;
}

.top-tags {
  bottom: auto;
  top: 35px;
}

.bottom-tags {
  top: auto;
  bottom: 35px;
}

.tags {
  color: #ffd700;
  opacity: 0.6;
  position: absolute;
  left: 120px;
  font-size: 18px;
  font-family: 'La Belle Aurore', cursive;
  display: flex;
  flex-direction: column;
}

.tag-body {
  margin-left: 20px;
}

.container {
  width: 100%;
  will-change: contents;
  height: 93%;
  min-height: 566px;
  position: absolute;
  opacity: 0;
  top: 5%;
  margin: 0 auto;
  z-index: 1;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
  display: flex;
  justify-content: space-around;
}

.container.contact-page,
.container.about-page,
.container.portfolio-page,
.container.jobs-page,
.container.portfolio-selected-page,
.container.jobs-selected-page{
  
    h1 {
      font-size: 53px;
      font-family: 'Coolvetica';
      color: #ffd700;
      font-weight: 400;
      margin-top: 0;
      position: relative;
      margin-bottom: 40px;
      left: -10px;

      &:before {
        content: '<h1>';
        font-family: 'La Belle Aurore', cursive;
        color: #ffd700;
        font-size: 18px;
        position: absolute;
        margin-top: -10px;
        left: -10px;
        opacity: 0.6;
        line-height: 18px;
      }

      &:after {
        content: '</h1>';
        font-family: 'La Belle Aurore', cursive;
        color: #ffd700;
        font-size: 18px;
        line-height: 18px;
        position: absolute;
        left: -30px;
        bottom: -20px;
        margin-left: 20px;
        opacity: 0.6;
      }
    }

    h2 {
      font-size: 35px;
      font-family: 'Coolvetica';
      color: #ffd700;
      font-weight: 400;
      margin-top: 40px;
      position: relative;
      margin-bottom: 40px;
      left: -10px;
      display: flex;

      &:before {
        content: '<h2>';
        font-family: 'La Belle Aurore', cursive;
        color: #ffd700;
        font-size: 18px;
        position: absolute;
        margin-top: -10px;
        left: -10px;
        opacity: 0.6;
        line-height: 18px;
      }

      &:after {
        content: '<h2/>';
        font-family: 'La Belle Aurore', cursive;
        color: #ffd700;
        font-size: 18px;
        line-height: 18px;
        position: absolute;
        left: -30px;
        bottom: -20px;
        margin-left: 20px;
        opacity: 0.6;
      }
    }

    p {
      font-size: 1.5rem;
      color: #fff;
      font-family: sans-serif;
      font-weight: 300;
      max-width: fit-content;
      animation: pulse 1s;
      text-align: justify;
      
      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }
      &:nth-of-type(2) {
        animation-delay: 1.2s;
      }
      &:nth-of-type(3) {
        animation-delay: 1.3s;
      }
      &:nth-of-type(4) {
        animation-delay: 1.4s;
      }
    }

    span.company {
      color: #8d8d8d;
      margin-top: 3rem;
      margin-bottom: 0;
      font-weight: 400;
      font-size: 15px;
      font-family: sans-serif;
      letter-spacing: 3px;
      animation: fadeIn 1s 1.8s backwards;
      display: flex;
    }

    ul {
      height: 5rem;
      overflow: hidden;
    }
    li {
      font-size: 1.25rem;
      color: #fff;
      list-style: none;

    }

  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 35%;
    vertical-align: middle;
    display: table-cell;
    max-height: 90%;
  }

  .text-animate-hover {
    &:hover {
      color: #fff;
    }
  }
}

@media (max-width:961px) {
  .tags {
    font-size: 2rem;
    left: 40px;
  }

  .top-tags {
    top: 80px;
  }
  
  .bottom-tags {
    bottom: 80px;
  }


.container.contact-page,
.container.about-page,
.container.portfolio-page,
.container.jobs-page,
.container.portfolio-selected-page,
.container.jobs-selected-page{
  .text-zone {
    width: 80%;
  }
  h1 {
    font-size: 7rem;
    line-height: 9rem;
    margin: 5rem;
    
    &:before, &:after {
      font-size: 2rem;
    }
  }
  p {
    font-size: 2rem;
  }
  h2 {
    color: white;
    font-size: 2rem;
  }
}

  .home-page {
    .text-zone {
      width: 90%;
      max-height: 90%;
      z-index: 1000;
      h1 {
        font-size: 6rem;
        line-height: 7.25rem;
      }
      .tags-h1 {
        font-size: 2rem;
      }
      h2 {
        color: white;
        font-size: 2rem;
      }
      .link {
        font-size: 2rem;
      }
    }
  }
}

@media (max-width:481px) {
  .tags {
    font-size: 2rem;
    left: 40px;
  }

  .top-tags {
    top: 80px;
  }
  
  .bottom-tags {
    bottom: 80px;
  }


.container.contact-page,
.container.about-page,
.container.portfolio-page,
.container.jobs-page,
.container.portfolio-selected-page,
.container.jobs-selected-page{
  .text-zone {
    width: 65%;
    height: 70%;
    left: 25%;
  }
  h1 {
    font-size: 3rem;
    line-height: 4rem;
    padding-top: 1rem;
    margin: 5rem;

    &:before, &:after {
      font-size: 2rem;
    }
  }
  p {
    font-size: 1.5rem;
    margin-top: 0;
    padding-right: 1rem;
  }
  h2 {
    color: white;
    font-size: 2rem;
  }
}

.container.about-page,
.container.contact-page,
.container.portfolio-selected-page,
.container.jobs-selected-page{
  h1 {
    margin: 0;
    padding: 0;
  }
  p {
    margin-top: 2.5rem;
  }
}

  .home-page {
    .text-zone {
      width: 90%;
      max-height: 90%;
      z-index: 1000;
      h1 {
        font-size: 3rem;
        line-height: 2.75rem;
      }
      .tags-h1 {
        font-size: 2rem;
      }
      h2 {
        color: white;
        font-size: 1.25rem;
      }
      .link {
        font-size: 1rem;
      }
    }
  }
}
