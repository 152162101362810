.container {
  justify-content: space-between !important;
  flex-direction: row-reverse !important;
}

.home-page {
  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 40%;
    max-height: 90%;
  }

  .tags-h1{
    color: #ffd700;
    opacity: 0.6;
    font-size: 18px;
    font-family: 'La Belle Aurore', cursive;
    animation: fadeIn 1s 1.7s backwards;
  }

  h1 {
    color: #fff;
    font-size: 56px;
    line-height: 53px;
    margin: 0;
    font-family: 'Coolvetica';
    font-weight: 400;

    img {
      margin: 0;
      margin-left: 20px;
      opacity: 0;
      width: 32px;
      height: auto;
      animation: rotateIn 1s linear both;
      animation-delay: 1.4s;
    }
  }

  h2 {
    color: #8d8d8d;
    margin-top: 20px;
    font-weight: 400;
    font-size: 15px;
    font-family: sans-serif;
    letter-spacing: 3px;
    animation: fadeIn 1s 1.8s backwards;
    display: flex;
  }

  .flat-button {
    color: #ffd700;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #ffd700;
    margin-top: 25px;
    float: left;
    animation: fadeInAnimation 1s 1.8s backwards;
    white-space: nowrap;
    width: 125px;

    &:hover {
      background: #ffd700;
      color: #333;
      animation: rubberBand 1s;
    }

  }
}

.flex-col {
  display: flex;
  flex-direction: column;
  h1 {
    margin-left: 2rem;
  }
}

.me-photo {
  right: 5rem;
  position: absolute;
  top: -5rem;
  bottom: 0;
  height: 125vh;
  mask-image: linear-gradient(
    var(--mask-direction, to right),
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 20%,
    hsl(0 0% 0% / 1) 80%,
    hsl(0 0% 0% / 0)
  );

  animation: fadeIn 3s 1.5s backwards;
}

.me-photo-div {
  background: url(../../assets/images/me.png);
  width: 50vw;
  height: 120vh;
  margin-right: 10rem;
  background-repeat: no-repeat;
  background-position: top right;
  background-size: cover;
  animation: fadeIn 3s 1.5s backwards;  
  mask-image: linear-gradient(
    var(--mask-direction, to right),
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 20%,
    hsl(0 0% 0% / 1) 80%,
    hsl(0 0% 0% / 0)
  );
}


@media (max-width:961px) {
  .me-photo-div {
    background: url(../../assets/images/me.png);
    width: 100vw;
    height: 100vh;
    background-position: center;
    background-size: cover;
    margin: 0;
    opacity: 50%;
    animation: fadeInMobile 3s 1.5s backwards;  
  }

  @keyframes fadeInMobile {
    0% { 
      opacity: 0; 
    }
    100% { 
      opacity: 50%; 
    }
  }

  .home-page {
    .text-zone {
      width: 90%;
      max-height: 90%;
      h1 {
        font-size: 7em;
        line-height: 1em;
      }
      .tags-h1 {
        font-size: 3em;
      }
      h2 {
        color: white;
        font-size: 2em;
      }
      .link {
        font-size: 2em;
      }
    }
  }
}