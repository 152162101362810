.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  margin: auto;
  max-width: 100vw;
  z-index: -1;
}

.wrapper--vertical {
  flex-direction: row;
  height: 100vh;
  justify-content: end;
  margin-right: 10%;
}


@media (max-width:961px) {
  .wrapper {
    opacity: 60%;
  }
  .wrapper--vertical {
    margin: auto;
  }
}

@media (max-width:481px) {
  .text-section {
    margin-top: 2rem;
    overflow: auto;
    height: 80%;
  }
  .portfolio-selected-page,
  .jobs-selected-page{
    .text-section {
      height: 40%;
    }
  }
}