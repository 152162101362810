.link {
  margin-top: 1rem;
}

.portfolio-images{ 
  height:400px;
  display: flex;
  justify-content: center;
}

.container.portfolio-selected-page{
  display: flex;
  .showcase {
    width: 50%;
    margin-right: 5%;
    display: flex;
    justify-content: flex-end;
  }

  .porfolio-figure-alone{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 50%;
    background-color: #351a7c;
    transition: all .5s ease-in-out;

    img {
      width: 100%;
    }
    .portfolio-figure-caption {
      margin-top: 1rem;
      font-size: 1.25rem;
      color: white;
    }
  }

  .close-hidden {
    display: none;
    opacity: 0;
  }

  .showcase-small{
    &.hide-showcase{
      display:none;
      opacity: 0;
    }
    &.show-showcase{
      width: 100%;
      display: flex;
      background-color: #361a7c;
      z-index: 1000;
      .close-visible {
        position: absolute;
        font-size: 3rem;
        color: white;
        top: 5rem;
        right: 3rem;
        z-index: 3000;
      }

      .marqueeAI__group{
        width: 100%;
      }

      .porfolio-figure-alone{
        width: 100%;
      }
    }
  }
}


.porfolio-figure-alone.hovered{
  transform: scale(1.5);
  transform-origin: center right;
}

.portfolio-url{
  color: #ffd700;
  font-size: 2rem;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;

  &:visited{
    color: #ffd700;
  }
}
