:root {
  --color-text: navy;
  --color-bg: papayawhip;
  --color-bg-accent: #ffd70036;
    --size: clamp(10rem, 1rem + 40vmin, 30rem);
  --gap: calc(var(--size) / 14);
  --duration: 60s;
  --scroll-start: 0;
  --scroll-end: calc(-100% - var(--gap));
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-text: papayawhip;
    --color-bg: navy;
    --color-bg-accent: #ffd70036;
  }
}

* {
  box-sizing: border-box;
}

body {
  display: grid;
  align-content: center;
  overflow: hidden;
  gap: var(--gap);
  width: 100%;
  min-height: 100vh;
  font-family: system-ui, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  color: var(--color-text);
  background-color: var(--color-bg);
}

.marquee {
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
  mask-image: linear-gradient(
    var(--mask-direction, to right),
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 20%,
    hsl(0 0% 0% / 1) 80%,
    hsl(0 0% 0% / 0)
  );
}

.marquee__group {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: var(--gap);
  min-width: 100%;
  animation: scroll-x var(--duration) linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .marquee__group {
    animation-play-state: paused;
  }
}

.marquee--vertical {
  --mask-direction: to bottom;
}

.marquee--vertical,
.marquee--vertical .marquee__group {
  flex-direction: column;
}

.marquee--vertical .marquee__group {
  animation-name: scroll-y;
}

.marquee--reverse .marquee__group {
  animation-direction: reverse;
  animation-delay: -3s;
}

@keyframes scroll-x {
  from {
    transform: translateX(var(--scroll-start));
  }
  to {
    transform: translateX(var(--scroll-end));
  }
}

@keyframes scroll-y {
  from {
    transform: translateY(var(--scroll-start));
  }
  to {
    transform: translateY(var(--scroll-end));
  }
}

/* Element styles */
.marquee .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--size);
  background: var(--color-bg-accent);
  aspect-ratio: 16/9;
  padding: calc(var(--size) / 10);
  border-radius: 0.5rem;

  svg {
    aspect-ratio: 16/9;
  }

  label {
    color: white;
    font-size: 2.5rem;
  }
}

.marquee--vertical .icon{
  padding: calc(var(--size) / 6);
}

.marquee--reverse .marquee__group {
  animation-direction: reverse;
  animation-delay: -3s;
}

@keyframes fade {
  to {
    opacity: 0;
    visibility: hidden;
  }
}