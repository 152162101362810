.nav-bar,  .nav-bar-top{
  background: #191122;
  width: 70px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1000;
  min-height: 500px;

  .logo {
    display: block;
    padding: 8px 0;

    img {
      display: block;
      margin: 8px auto;
      width: 24px;
      height: auto;

      &.sub-logo {
        width: 50px;
      }
    }
  }

  nav {
    display: block;
    text-align: center;
    position: absolute;
    height: 210px;
    top: 50%;
    margin-top: -120px;
    width: 100%;

    a {
      font-size: 22px;
      color: #4d4d4e;
      display: block;
      line-height: 51px;
      height: 51px;
      position: relative;
      text-decoration: none;

      i {
        transition: all 0.3s ease-out;
      }

      &:hover {
        color: #ffd700;

        svg {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }

      &:after {
        content: '';
        font-size: 9px;
        letter-spacing: 2px;
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        text-align: center;
        opacity: 0;
        -webkit-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
      }
    }

    a.home-link:after, a.about-link:after, a.contact-link:after, a.works-link:after,  a.portfolio-link:after{
      display: flex;
      justify-content: center;
      align-items: center;
    }    
    
    a.home-link {
      &:after {
        content: 'HOME';
      }
    }

    a.about-link {
      &:after {
        content: 'ABOUT';
      }
    }

    a.contact-link {
      &:after {
        content: 'CONTACT';
      }
    }

    a.works-link {
      &:after {
        content: 'WORKS';
      }
    }
    a.portfolio-link {
      &:after {
        content: 'PORTFOLIO';
      }
    }

    a.active {
      svg {
        color: #ffd700;
      }
    }
  }

  ul {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: block;
    padding: 0;
    list-style: none;
    text-align: center;
    margin: 0;

    li {
      a {
        padding: 7px 0;
        display: block;
        font-size: 15px;
        line-height: 16px;
        color: #4d4d4e;

        &:hover {
          color: #ffd700;
        }
      }
    }
  }
}

.icon {
  width: 5rem;
  color: #ffd700;
  display: inline-block;
}

.hi-hand {
  animation-name: wave-hand;
  animation-duration: 2.5s; 
  animation-iteration-count: infinite;  
  transform-origin: 50% 70%; 
}

@media (max-width:961px) {
  .nav-bar-top {
    width: 100vw;
    height: 70px;
    min-height: 70px;
    position: absolute;
    top: 0;
    z-index: 3;
    background: #191122;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .extra-info {
    position: absolute;
    width: 100vw;
    height: 165px;
    margin-top: 20px;
    background: #14012e;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .nav-bar {
    width: 100vw;
    height: 70px;
    min-height: 70px;
    position: absolute;
    bottom: 0;
    top: auto;
    z-index: 3;
    display: flex;
    .logo {
      display: none;
      visibility: hidden;
    }
    nav {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      height: auto;
      top: auto;
      margin-top: 1rem;
    }
    ul {
      display: none;
      visibility: hidden;
    }
  }
  
  a.home-link:after, a.about-link:after, a.contact-link:after, a.works-link:after,  a.portfolio-link:after{
    color: #ffd700;
  }  
}

@keyframes wave-hand {
  0% { transform: rotate( 0.0deg) }
  10% { transform: rotate(16.0deg) }  
  20% { transform: rotate(-8.0deg) }
  30% { transform: rotate(20.0deg) }
  40% { transform: rotate(-4.0deg) }
  50% { transform: rotate(10.0deg) }
  60% { transform: rotate( 0.0deg) }  
  100% { transform: rotate( 0.0deg) }
}