.portfolio-zone {
  position: absolute;
  left: 10%;
  top: 50%;
  transform: translateY(-50%);
  width: 85%;
  vertical-align: middle;
  display: table-cell;
  max-height: 90%;
}
  
.portfolio-container {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  padding-bottom: 100px;
}

.portfolio-box {
  display: block !important;
  position: relative;
  flex: 1 1 20%;
  height: 20rem;
  overflow: hidden;
  border-radius: 10px;
  width: 100dvw !important;
  max-width: calc(25% - 10px);
}

.portfolio-section {
  margin: 0 80px;
  height: 100%;
  overflow: auto;
}

@media (max-width:961px) {
  .portfolio-section {
    margin: 0;
    height: 100%;
    overflow: auto;
  }

  .portfolio-container {
    display: block;
  }

  .slick-list {
    height: auto !important;
    margin: 2rem !important;
  }
  
  .slick-slider {
    margin: 3rem;
  }

  .slick-slide {
    height: 33vh;
    object-fit: contain;
    object-position: center;
    width: 70%;
  }

  .slick-slide div {
    color: white
  }

  .portfolio-box {
    width: 100%;
    max-width: 100%;
    height: 20rem;
  }
}