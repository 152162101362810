h2 {
  margin: 0 0 30px;
  padding: 0;
  color: #fff;
  text-align: center;
}

.link {
  position: relative;
  display: inline-block;
  padding: 10px 18px;
  color: #ffd700;
  font-size: 13px;
  font-weight: 400;
  font-family: sans-serif;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  // margin-top: 25px;
  letter-spacing: 4px;
  animation: fadeInAnimation 1s 1.8s backwards;
  white-space: nowrap;
}

.link:hover {
  background: #ffd700;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #ffd700,
              0 0 25px #ffd700,
              0 0 50px #ffd700,
              0 0 100px #ffd700;
}

.link span {
  position: absolute;
  display: block;
}

.link span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #ffd700);
  animation: btn-anim1 1s linear infinite;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%,100% {
    left: 100%;
  }
}

.link span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #ffd700);
  animation: btn-anim2 1s linear infinite;
  animation-delay: .25s
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }
  50%,100% {
    top: 100%;
  }
}

.link span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #ffd700);
  animation: btn-anim3 1s linear infinite;
  animation-delay: .5s
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }
  50%,100% {
    right: 100%;
  }
}

.link span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #ffd700);
  animation: btn-anim4 1s linear infinite;
  animation-delay: .75s
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,100% {
    bottom: 100%;
  }
}
