.contact-form {
  width: 100%;
  margin-top: 20px;

  .form {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 10px;
    .first-row {
      display: flex;
      gap: 10px;
      justify-content: flex-end;
      .name {
        padding-right: 5px;
      }
    }
  }

  input[type='text'],
  input[type='email'] {
    width: 100%;
    border: 0;
    background: #ffd70036;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 0 20px;
    box-sizing: border-box;
  }

  textarea {
    width: 100%;
    border: 0;
    background: #ffd70036;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 20px;
    box-sizing: border-box;
    min-height: 150px;
  }
}
.button-row {
  display: flex;
  justify-content: flex-end;
  .flat-button {
    font-size: 11px;
    letter-spacing: 3px;
    text-decoration: none;
    padding: 8px 10px;
    border: 1px solid #ffd700;
    border-radius: 4px;
    background: 0 0;
    text-transform: uppercase;
    text-align: center;
    min-width: 100px;
    .btn-text {
      color: #ffd700;
      font-size: 11px;
      letter-spacing: 3px;
      text-decoration: none;
      padding: 0;
      border: 0;
      background: transparent;
      text-transform: uppercase;
      text-align: center;
    }
    .loading {
      color: #d6d2bb;
      animation-name: spin;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-timing-function: linear; 
    }
  }
}


.flat-button.disabled{
  border-color: #d6d2bb;
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}


@media (max-width:961px) {
  .first-row {
    flex-direction: column;
  }
  .show-info-card {
    display: none;
  }
  .contact-me-icon {
    display: block;
    position: absolute;
    bottom: 7rem;
    right: 4rem;
    border: solid .15rem #ffd700;
    background: radial-gradient(rgba(255, 217, 0, 0.2) 30%, rgba(255, 217, 0, 0.4) 60%, #ffd700 75%);
    border-radius: 50%;
    padding: 1rem;
    cursor: pointer;
    svg {
      color: #ffd700;
      animation-name: wave-hand;
      animation-duration: 2.5s; 
      animation-iteration-count: infinite;  
      transform-origin: 50% 70%; 
      display: inline-block;
    }
  }
  .contact-form {
    input[type='text'],
    input[type='email'] {
      height: 30px;
      font-size: 13px;
      padding: 0 10px;
      box-sizing: border-box;
    }

    textarea {
      height: 50px;
      font-size: 13px;
      color: #fff;
      padding: 10px;
      min-height: 100px;
    }
  }
}
